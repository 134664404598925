import React from 'react';
import UserContextProvider from './UserContext';

interface IProps {
    children: React.ReactNode;
}

const ContextCombiner: React.FC<IProps> = (props: IProps) => {
    return <UserContextProvider>{props.children}</UserContextProvider>;
};

export default ContextCombiner;
